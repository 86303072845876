var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-org"},[_c('Header',{on:{"dismissClicked":_vm.setDismissed}}),(!_vm.isLoading)?_c('v-container',{staticClass:"my-3"},[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbsItems}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('h1',[_vm._v("Notifications")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Notifications")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-5 mb-15 align-center",attrs:{"loading":_vm.dataLoading,"loading-text":"Loading... Please wait","item-key":"id","headers":_vm.headers,"items":_vm.items,"single-expand":"","options":_vm.options,"server-items-length":_vm.itemsTotal,"show-expand":"","footer-props":{
                itemsPerPageOptions: [10]
              }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.user_announcements.dismissed)?_c('v-icon',{staticClass:"dismissed"},[_vm._v(" mdi-checkbox-blank-circle ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"not-dismissed",on:{"click":function($event){return _vm.dismissNotification(item.id)}}},on),[_vm._v(" mdi-checkbox-blank-circle ")])]}}],null,true)},[_c('span',[_vm._v("Mark as read")])])],1)]}},{key:"expanded-item",fn:function(ref){
              var item = ref.item;
return [_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"my-5 ml-5",domProps:{"innerHTML":_vm._s(item.body)}})])]}}],null,false,1744885817)})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-snackbar',{attrs:{"right":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}],null,false,508673173),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")])],1)],1)],1):[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center text-overline mb-8"},[_vm._v("Loading new data")]),_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","rounded":"","height":"6"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }