<template>
  <div class="dashboard-org">
    <Header @dismissClicked="setDismissed"></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>Notifications</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark>Notifications</v-toolbar>
            <v-card-text>
              <v-data-table
                :loading="dataLoading"
                loading-text="Loading... Please wait"
                item-key="id"
                :headers="headers"
                :items="items"
                single-expand
                :options.sync="options"
                :server-items-length="itemsTotal"
                show-expand
                class="elevation-5 mb-15 align-center"
                :footer-props="{
                  itemsPerPageOptions: [10]
                }"
              >
              <template v-slot:item.action="{ item }">
                <td>
                   <v-icon 
                      class="dismissed"
                      v-if="item.user_announcements.dismissed"
                    >
                      mdi-checkbox-blank-circle
                    </v-icon>

                  <v-tooltip v-else bottom>
                    <template #activator="{ on }">
                      <v-icon 
                        class="not-dismissed"
                        v-on="on"
                        @click="dismissNotification(item.id)"
                      >
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </template>
                    <span>Mark as read</span>
                  </v-tooltip>
                </td>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td colspan="4">
                  <div class="my-5 ml-5" v-html="item.body" />
                </td>
              </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import AnnouncementsService from "../services/announcements.service";

export default {
  name: "ManageCategories",
  components: {
    Header
  },
  data() {
    return {
      search: "",
      breadcrumbsItems: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Notifications",
          disabled: true,
          href: "/notifications"
        }
      ],
      editedItem: {
        name: "",
        description: ""
      },
      originalItem: {
        name: "",
        description: ""
      },
      defaultlItem: {
        name: "",
        description: ""
      },
      items: [],
      isLoading: false,
      snackbar: false,
      valid: true,
      alert: "",
      dataLoading: true,
      options: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      headers: [
        { text: "Title", value: "title" },
        { text: "Message", value: "message" },
        {
          text: "",
          value: "action",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],
      editedIndex: -1
    };
  },

  watch: {
    options: {
      async handler() {
        if (this.lastPage !== null) {
          this.getItems();
        }
      },
      deep: true
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  methods: {

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.getItems();
    },

    async getItems() {
      this.dataLoading = true;
      const { page } = this.options;

      const response = await AnnouncementsService.get({
        query: {
          page
        }
      });

      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.items = response.data.data.slice();
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.dataLoading = false;
    },

    async dismissNotification(id) {
      //update the status
      const index = this.items.findIndex(item => item.id === id);
      this.items[index].user_announcements.dismissed = 1;

      this.items.map(item => {
        if (item.id === id) {
          var newItem = Object.assign({}, item);
          newItem.user_announcements.dismissed = 1;
          return newItem;
        } else {
          return item;
        }
      });


      //set the dissmissed to 1 in the DB
      await AnnouncementsService.remove({
        id
      });
      this.$notify.close(id);
    },

    async setDismissed(id) {
      this.items.map(item => {
        if (item.id === id) {
          var newItem = Object.assign({}, item);
          newItem.user_announcements.dismissed = 1;
          return newItem;
        } else {
          return item;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.mdi-checkbox-blank-circle.not-dismissed {
  color: red;
}
.mdi-checkbox-blank-circle.dismissed {
  color: #cccccc;
}
.full-width-buttons button.v-btn {
  width: 100% !important;
}
.disabled_check.v-icon {
  color: rgba(0, 0, 0, 0.14);
}
.back-to-blue {
  background-color: #C5E1E9 !important;
  color: #2F91AE;
}
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
</style>
